<template>
  <div>
    <PageHeader>
      <template slot="right">
        <div>
          <b-button
            variant="primary"
            to="/bank-mobile-app/create"
          >
            <i class="uil-plus mr-1"></i>
            {{ $t('buttons.add') }}
          </b-button>
        </div>
      </template>
    </PageHeader>
    <b-card>
      <div class="d-flex justify-content-between align-items-center">
        <h5>
          {{ `บัญชีโมบายแบ้งค์กิ้ง (${bankAccounts.length})` }}
        </h5>
      </div>
      <small class="form-text text-muted"> AccountStatus: 1 = ลงทะเบียนแล้ว, 2 = ลงทะเบียนโดยยังไม่ยืนยัน OTP, 3 = ลงทะเบียนโดยยืนยัน OTP แล้ว, 0 = กรุณาลงทะเบียนใหม่
        <br /> BankUsageStatus: 1 = ฝากเท่านั้น, 2 = ถอนเท่านั้น, 3 = ฝากและถอน
        <hr />
        *กรุณาปฏิบัติตามขั้นตอนดังนี้ก่อนที่จะลงทะเบียนบัญชีในหน้าระบบนี้ ( ระบบฝากถอนอัติโนมัติผ่าน SCB Apps ใช้ได้เฉพาะกับโทรศัพท์ Android เท่านั้น !! )
        <br />ถ้าเว็บตอบกลับว่าให้ลงทะเบียนด้วยแอปก่อน กรอก OTP ให้โหลดแอปจาก <a href="https://raw.githubusercontent.com/Banktopup/api-v1/master/banktopup_register.apk">https://raw.githubusercontent.com/Banktopup/api-v1/master/banktopup_register.apk</a>
        <br />
        <br />1) กดติดตั้งแอป
        <br />2) ใส่ซิมของบัญชีท่าน
        <br />3) เปิดใช้เน็ตของซิมมือถือ
        <br />4) กด ตรวจสอบเบอร์
        <br />5) กด ลงทะเบียน เสร็จแล้วไปขั้นตอนต่อไปได้เลย ถ้าเบอร์ได้ลงทะเบียนแล้วอนาคตไม่ต้องลงทะเบียนซ้ำอีก นอกจากเปลี่ยนเบอร์ที่ผูกกับบัญชี
      </small>
      <br />
      <b-table
        :items="bankAccounts"
        :fields="fields"
        :busy="isLoading"
        responsive
        show-empty
      >
        <template #cell(bank_code)="data">
          {{ $displayBankName(data.value) }}
        </template>
        <template #cell(limit)="data">
          {{ data.value | currency }}
        </template>
        <template #cell(is_enable)="data">
          <b-badge :variant="data.value ? 'success' : 'danger'">
            {{
              data.value
                ? $t(`utils.status.active`)
                : $t(`utils.status.inactive`)
            }}
          </b-badge>
        </template>
        <template #cell(first_name)="data">
          {{ data.value }} {{ data.item.last_name }}
        </template>
        <template #cell(id)="data">
          <b-dropdown
            variant="info"
            size="sm"
            dropleft
          >
            <template #button-content>
              <i class="uil uil-cog"></i>
            </template>
            <b-dropdown-item @click="onSelect(data.item)">
              <i class="uil uil-edit-alt mr-2"></i>
              {{ $t('buttons.edit') }}
            </b-dropdown-item>
            <b-dropdown-item @click="onActivate(data.item)">
              <i class="uil uil-power mr-2"></i>
              Activate
            </b-dropdown-item>
            <b-dropdown-item
              variant="danger"
              @click="onDeactivate(data.item)"
            >
              <i class="uil uil-trash-alt mr-2"></i>
              <span>
                De-Active
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template #empty="">
          <p class="text-center text-muted">{{
            $t('messages.nothing_here')
          }}</p>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
      </b-table>
      <!-- pagination -->
        <PaginationInput :per-page="limit" :total="totalRow" @update="(val) => currentPage = val"/>
    </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CoreBankService from '../../../services/bank'

export default {
  page: {
    title: 'Bank Mobile App',
  },
  data() {
    return {
      currentPage: 1,
      limit: 50,
      offset: 0,
      isLoading: false,
      fields: [
        {
          key: 'account_license',
          label: 'เบอร์',
          thStyle: {},
        },
        {
          key: 'first_name',
          label: 'ชื่อจริง-นามสกุล',
        },
        {
          key: 'mobile_phone_no',
          label: 'mobile_phone_no',
        },
        {
          key: 'bank_usage_status',
          label: 'สถานะ',
        },
        {
          key: 'id',
          label: 'แก้ไข',
        },
      ],
      userBankAccounts: {
        data: [],
      },
    }
  },
  computed: {
    ...mapState(['user']),
    bankAccounts() {
      return this.userBankAccounts.data || []
    },
    totalRow() {
      return this.userBankAccounts.total
    },
  },
  watch: {
    currentPage(val) {
      const nextPage = val - 1
      this.offset = nextPage * this.limit + 1
      this.fetchData()
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        this.userBankAccounts = await CoreBankService.MobileApps({
          page: this.offset + 1,
          limit: this.limit,
        })
      } catch (e) {
        await this.$store.dispatch('notifyError', e)
      }
    },
    async onActivate(data) {
      try {
        await CoreBankService.MobileAppActivate(data.id)
        this.$store.dispatch('notifySuccess', 'activated')
      } catch (e) {
        await this.$store.dispatch('notifyError', e)
      }
      this.fetchData()
    },
    async onDeactivate(data) {
      try {
        await CoreBankService.MobileAppDeActivate(data.id)
        this.$store.dispatch('notifySuccess', 'de-activated')
      } catch (e) {
        await this.$store.dispatch('notifyError', e)
      }
      this.fetchData()
    },
    async onSelect(data) {
      await this.$router.push(`/bank-mobile-app/${data.id}`)
    },
  },
}
</script>
