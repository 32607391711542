import axios from 'axios'

const baseURL = `${process.env.VUE_APP_API_URL}/user`
const CoreBankService = {
  MobileAppRegister({
    agent_id,
    first_name,
    last_name,
    identification,
    year,
    month,
    day,
    pin,
    mobile_phone_no,
    account_no,
    account_license,
    device_brand,
    device_code,
    bank_usage_status,
  }) {
    return axios.post(`${baseURL}/kitti/register`, {
      agent_id,
      first_name,
      last_name,
      identification,
      year,
      month,
      day,
      pin,
      mobile_phone_no,
      account_no,
      account_license,
      device_brand,
      device_code,
      bank_usage_status,
    })
  },
  MobileAppOTPVerify(id, { otp }) {
    return axios.post(`${baseURL}/kitti/otp-verify/${id}`, {
      otp,
    })
  },
  MobileAppActivate(id) {
    return axios.post(`${baseURL}/kitti/activate/${id}`)
  },
  MobileAppDeActivate(id) {
    return axios.post(`${baseURL}/kitti/deactivate/${id}`)
  },
  fetchTruewallet() {
    try {
      return axios.get(`${baseURL}/truewallet`)
    } catch (e) {
      throw e
    }
  },
  requestOTPTruewallet(walletId) {
    return axios.get(`${baseURL}/truewallet/request-login-otp/${walletId}`)
  },
  submitOTPTruewallet(walletId, data) {
    return axios.post(
      `${baseURL}/truewallet/submit-login-otp/${walletId}`,
      data
    )
  },
  createTruewalletAccount(data) {
    try {
      return axios.post(`${baseURL}/truewallet/create`, data)
    } catch (e) {
      throw e
    }
  },
  fetchRawTxn({
    page = 1,
    limit = 20,
    date,
    order,
    transaction_description,
    is_sync = false,
  }) {
    try {
      return axios.get(`${baseURL}/admins/logs/raw-txn`, {
        params: {
          page,
          limit,
          order,
          transaction_description,
          date,
          is_sync,
        },
      })
    } catch (e) {
      throw e
    }
  },
  fetchTruewalletById(bankAccountId) {
    try {
      return axios.get(`${baseURL}/truewallet/detail/${bankAccountId}`)
    } catch (e) {
      throw e
    }
  },
  updateTruewalletAccount(id, bankData) {
    try {
      return axios.post(`${baseURL}/truewallet/update/${id}`, bankData)
    } catch (e) {
      throw e
    }
  },
  removeTrueWalletAccount(id) {
    try {
      return axios.delete(`${baseURL}/truewallet/delete/${id}`)
    } catch (e) {
      throw e
    }
  },
  MobileApps({ page, limit = 50 }) {
    return axios.get(`${baseURL}/kitti`, {
      params: {
        page,
        limit,
      },
    })
  },
}

export default CoreBankService
